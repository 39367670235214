import { Box, HStack, IconButton } from "@chakra-ui/react";
import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <HStack spacing={2} justify="center" my={10}>
      <IconButton
        aria-label="Previous Page"
        icon={<FaChevronLeft />}
        onClick={handlePreviousPage}
        isDisabled={currentPage === 1}
      />
      <Box>
        Page {currentPage} of {totalPages}
      </Box>
      <IconButton
        aria-label="Next Page"
        icon={<FaChevronRight />}
        onClick={handleNextPage}
        isDisabled={currentPage === totalPages}
      />
    </HStack>
  );
};

export default Pagination;
