import { Grid, GridItem } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setLogo } from "../../redux/features/logoSlice";
import { getSettingsData } from "../../services/axios.services";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

export default function SharedLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const logoData = await getSettingsData();
      dispatch(setLogo({ logo: logoData.data.logo }));
    };
    fetchData();
  }, []);

  return (
    <Grid templateRows={"80px 1fr"} templateColumns={"250px 1fr"}>
      <GridItem rowSpan={2} colSpan={1}>
        <Sidebar />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <Navbar />
      </GridItem>
      <GridItem rowSpan={1} colSpan={1}>
        <Outlet />
      </GridItem>
    </Grid>
  );
}
