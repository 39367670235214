import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Barcode from "../Barcode/Barcode";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: "center",
    fontWeight: "bold",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  text: {
    fontSize: 12,
  },
  table: {
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableHeader: {
    fontWeight: "bold",
  },
  tableCell: {
    fontSize: 10,
  },
  customerNote: {
    fontSize: 10,
    marginTop: 20,
    marginBottom: 50,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default function GeneratePDF({ data }: any) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {data.map((item: any, index: number) => (
          <View key={index}>
            <Text style={{ fontSize: 8, textAlign: "center" }}>
              This invoice is computer generated.
            </Text>

            <View style={styles.flexContainer}>
              <View style={styles.section}>
                <Barcode value={item.orderId} />
                <Text style={styles.text}>Order No: {item.orderId}</Text>
                <Text style={styles.text}>
                  Date: {new Date(item.orderCreatedDate).toISOString()}
                </Text>
                <Text style={styles.text}>Name: {item.customer.name}</Text>
                <Text style={styles.text}>
                  Contact No: {item.customer.phone}
                </Text>
              </View>

              <View style={styles.section}>
                <Image
                  style={{ height: 60, width: 120 }}
                  src={item.logoUrl || "/assets/logo.png"}
                />
                {/* <Text style={styles.text}>Hotline: {item.hotline}</Text> */}
                {/* <Text style={styles.text}>{item.companyAddress}</Text> */}
              </View>
            </View>

            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={[styles.tableCol, styles.tableCell]}>PRODUCT</Text>
                <Text style={[styles.tableCol, styles.tableCell]}>PRICE</Text>
                <Text style={[styles.tableCol, styles.tableCell]}>QTY</Text>
                <Text style={[styles.tableCol, styles.tableCell]}>
                  TOTAL PRICE
                </Text>
              </View>
              {item.products.map((product: any, prodIndex: number) => (
                <View key={prodIndex} style={styles.tableRow}>
                  <Text style={[styles.tableCol, styles.tableCell]}>
                    {product.nameEnglish}
                  </Text>
                  <Text style={[styles.tableCol, styles.tableCell]}>
                    {product.perPrice}
                  </Text>
                  <Text style={[styles.tableCol, styles.tableCell]}>
                    {product.quantity}
                  </Text>
                  <Text style={[styles.tableCol, styles.tableCell]}>
                    {product.totalPrice}
                  </Text>
                </View>
              ))}
            </View>

            <View style={styles.section}>
              <Text style={styles.text}>
                Total: {item.afterDiscountTotalProductPrice} Tk
              </Text>
              <Text style={styles.text}>
                Delivery Charge: {item.deliveryCharge} Tk
              </Text>
              <Text style={styles.text}>
                Discount Amount: {item.discountPrice} Tk
              </Text>
              <Text style={styles.text}>
                Due(Collectable Amount): {item.duePrice} Tk
              </Text>
            </View>

            <View style={styles.customerNote}>
              <Text>Customer Note:</Text>
              <Text>
                1) If there is any problem with the product, please report it
                within 24 hours.
              </Text>
              <Text>
                2) All products are under warranty as per company policy.
              </Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
}
