import { Box, Button, Collapse, Flex, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import {
  FaBookOpen,
  FaChevronDown,
  FaChevronUp,
  FaRegDotCircle,
  FaUser,
  FaUserTag,
  FaUserTie,
} from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { IoIosSettings } from "react-icons/io";
import { MdBookmark, MdDashboard } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";

const Sidebar = () => {
  const navigate = useNavigate();
  // const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);

  const logo = useSelector((state: RootState) => state.logo.logo);

  // const toggleMainMenu = () => {
  //   setIsOpen(!isOpen);
  // };

  const toggleSubMenu = (submenuName: string) => {
    setOpenSubmenu((prevOpenSubmenu) =>
      prevOpenSubmenu === submenuName ? null : submenuName
    );
  };

  // console.log("logo -> ", logo);

  return (
    <Box
      as="aside"
      width="250px"
      height="100vh"
      bg="rgb(34,97,195)"
      bgGradient="linear-gradient(10deg, rgba(34,97,195,1) 43%, rgba(49,149,218,1) 79%)"
      color="white"
      p={5}
      boxShadow="md"
      pos={"fixed"}
      overflowY={"auto"}
    >
      <Box bg={"white"} p={3} m={5} mb={10} onClick={() => navigate("/")}>
        {logo ? (
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${logo}`}
            alt="kendrobindu"
          />
        ) : (
          <img src="/assets/logo.png" alt="kendrobindu" />
        )}
      </Box>
      <VStack align="start" spacing={2} mt={2}>
        <Button
          colorScheme="white"
          leftIcon={<MdDashboard />}
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </Button>

        <Button
          onClick={() => toggleSubMenu("products")}
          colorScheme="white"
          width="100%"
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              <FaBookOpen />
              <Text marginLeft="8px">Products</Text>{" "}
              {/* Adjust margin as needed */}
            </Flex>
            {openSubmenu === "products" ? <FaChevronUp /> : <FaChevronDown />}
          </Flex>
        </Button>
        <Collapse in={openSubmenu === "products"}>
          <VStack align="start" spacing={1} pl={4}>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/create-product")}
            >
              Add Product
            </Button>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/all-products")}
            >
              Product List
            </Button>
          </VStack>
        </Collapse>
        <Button
          colorScheme="white"
          onClick={() => navigate("/orders")}
          leftIcon={<MdBookmark />}
        >
          Orders
        </Button>
        <Button
          colorScheme="white"
          onClick={() => navigate("/categories")}
          leftIcon={<ImBooks />}
        >
          Categories
        </Button>
        <Button
          onClick={() => toggleSubMenu("authors")}
          colorScheme="white"
          width="100%"
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              <FaUserTie />
              <Text marginLeft="8px">Authors</Text>{" "}
              {/* Adjust margin as needed */}
            </Flex>
            {openSubmenu === "authors" ? <FaChevronUp /> : <FaChevronDown />}
          </Flex>
        </Button>
        <Collapse in={openSubmenu === "authors"}>
          <VStack align="start" spacing={1} pl={4}>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/author-registration")}
            >
              Add Author
            </Button>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/pending-authors")}
            >
              Pending Authors
            </Button>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/all-authors")}
            >
              Authors List
            </Button>
          </VStack>
        </Collapse>
        <Button
          onClick={() => toggleSubMenu("translators")}
          colorScheme="white"
          width="100%"
        >
          <Flex justifyContent="space-between" width="100%">
            <Flex alignItems="center">
              <FaUserTag />
              <Text marginLeft="8px">Translators</Text>{" "}
              {/* Adjust margin as needed */}
            </Flex>
            {openSubmenu === "translators" ? (
              <FaChevronUp />
            ) : (
              <FaChevronDown />
            )}
          </Flex>
        </Button>
        <Collapse in={openSubmenu === "translators"}>
          <VStack align="start" spacing={1} pl={4}>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/translator-registration")}
            >
              Add Translator
            </Button>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/pending-translators")}
            >
              Pending Translators
            </Button>
            <Button
              colorScheme="white"
              leftIcon={<FaRegDotCircle />}
              onClick={() => navigate("/all-translators")}
            >
              Translators List
            </Button>
          </VStack>
        </Collapse>
        <Button
          colorScheme="white"
          leftIcon={<FaUser />}
          onClick={() => navigate("/user-list")}
        >
          Users
        </Button>
        <Button
          colorScheme="white"
          leftIcon={<IoIosSettings />}
          onClick={() => navigate("/settings")}
        >
          Settings
        </Button>
      </VStack>
      {/* </Collapse> */}
      {/* <Divider my={4} borderColor="whiteAlpha.500" />
      <Text fontSize="sm" textAlign="center">
        © 2024 My Application
      </Text> */}
    </Box>
  );
};

export default Sidebar;
