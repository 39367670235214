import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination/Pagination";
import { Product } from "../interfaces/AllProducts.interface";
import { RootState } from "../redux/store";
import { getAllProducts } from "../services/axios.services";

export default function AllProducts() {
  const token = useSelector((state: RootState) => state.user.token);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 16,
  });
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const fetchProducts = async (page: number) => {
    const products = await getAllProducts(page, 16, token as string);
    setAllProducts(products.data.books);
    setTotalPages(Math.ceil(products.data.total / 16));
  };

  useEffect(() => {
    fetchProducts(pagination.pageIndex + 1);
  }, [pagination.pageIndex]);

  const columns: ColumnDef<Product, any>[] = [
    {
      header: "Image",
      accessorKey: "image",
      cell: ({ row }) => (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${row.original.image}`}
          alt="Product"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      header: "SKU",
      accessorKey: "sku",
    },
    {
      header: "Name",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.nameBangla}</Text>
          <Text>{row.original.nameEnglish}</Text>
        </Box>
      ),
    },
    {
      header: "Authors",
      cell: ({ row }) => (
        <Box>
          {row.original.authors.map((author, index) => (
            <Text key={index}>
              {index + 1}. {author.nameBangla} {author.nameEnglish}
            </Text>
          ))}
        </Box>
      ),
    },
    {
      header: "Regular Price",
      accessorKey: "salesPrice",
    },
    {
      header: "Discounted Price",
      accessorKey: "afterDiscountPrice",
    },
    {
      header: "Stock",
      accessorKey: "quantity",
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <Box>
          <IconButton
            aria-label="View Product"
            icon={<FaEye />}
            onClick={() => {
              onOpen();
              setSelectedProduct(row.original);
            }}
          />
          <IconButton
            ml={1}
            aria-label="Edit Product"
            icon={<FaEdit />}
            onClick={() => {
              navigate(`/edit-product/${row.original._id}`);
            }}
          />
        </Box>
      ),
    },
  ];

  const table = useReactTable({
    data: allProducts,
    columns,
    pageCount: totalPages,
    state: {
      pagination,
    },
    onPaginationChange: setPagination, // Update pagination state directly
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true, // Handle pagination manually
  });

  const handlePageChange = (page: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: page - 1 }));
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns={"150px 1fr"} gap={5}>
              <GridItem>
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${selectedProduct?.image}`}
                  alt="Product"
                  style={{ width: "150px", height: "230px" }}
                />
              </GridItem>
              <GridItem>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Product Name(BN):{" "}
                  </Text>
                  {selectedProduct?.nameBangla}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Product Name(EN):
                  </Text>{" "}
                  {selectedProduct?.nameEnglish}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Authors:
                  </Text>{" "}
                  {selectedProduct?.authors
                    ?.map((item) => item?.nameBangla)
                    .join(",")}
                </Text>
                {selectedProduct?.translators?.length > 0 && (
                  <Text>
                    <Text as={"span"} fontWeight={"bold"}>
                      Translators:
                    </Text>{" "}
                    {selectedProduct?.translators
                      ?.map((item: any) => item?.nameBangla)
                      .join(",")}
                  </Text>
                )}
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    SKU:
                  </Text>{" "}
                  {selectedProduct?.sku}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Edition:
                  </Text>{" "}
                  {selectedProduct?.edition}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    ISBN:
                  </Text>{" "}
                  {selectedProduct?.isbn}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Order Type:
                  </Text>{" "}
                  {selectedProduct?.orderType}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Regular Price:
                  </Text>{" "}
                  {selectedProduct?.salesPrice}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Discounted Price:
                  </Text>{" "}
                  {selectedProduct?.afterDiscountPrice}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Stock:
                  </Text>{" "}
                  {selectedProduct?.quantity}
                </Text>
                <Text>
                  <Text as={"span"} fontWeight={"bold"}>
                    Total Sell:
                  </Text>{" "}
                  {selectedProduct?.sellingCount}
                </Text>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Heading as={"h3"} size={"lg"} my={5} ml={5}>
        Product List
      </Heading>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: " 🔼",
                      desc: " 🔽",
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={pagination.pageIndex + 1}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
