import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { pdf } from "@react-pdf/renderer";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useReducer, useRef, useState } from "react";
import { FaCalendarDay, FaEdit, FaEye } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../components/Pagination/Pagination";
import GeneratePDF from "../components/PDF/GeneratePDF";
import { Order } from "../interfaces/OrderList.interface";
import { RootState } from "../redux/store";
import {
  changeStatusOrder,
  downloadCsv,
  fetchOrdersFromSearch,
  getOrders,
  multiOrderChangeStatus,
  printPdf,
} from "../services/axios.services";

export default function OrderList() {
  const token = useSelector((state: RootState) => state.user.token);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const {
    isOpen: isOrderDetailsOpen,
    onOpen: onOrderDetailsOpen,
    onClose: onOrderDetailsClose,
  } = useDisclosure();
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();
  const navigate = useNavigate();

  const rerender = useReducer(() => ({}), {})[1];

  const [rowSelection, setRowSelection] = useState({});
  const [allOrders, setAllOrders] = useState<Order[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 16,
  });
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [multiSelectedOrder, setMultiSelectedOrder] = useState<Order[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<string>("");

  useEffect(() => {
    const date = new Date();
    if (selectedDateRange === "Today") {
      const formattedDate = date.toISOString().slice(0, 10);
      setStartDate(formattedDate);
      setEndDate(formattedDate);
    } else if (selectedDateRange === "Yesterday") {
      date.setDate(date.getDate() - 1);
      const formattedDate = date.toISOString().slice(0, 10);
      setStartDate(formattedDate);
      setEndDate(formattedDate);
    } else if (selectedDateRange === "Last 7 Days") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 7);
      const formattedDate = newDate.toISOString().slice(0, 10);
      setStartDate(formattedDate);
      setEndDate(formattedToday);
    } else if (selectedDateRange === "Last Month") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 30);
      const formattedDate = newDate.toISOString().slice(0, 10);
      setStartDate(formattedDate);
      setEndDate(formattedToday);
    } else if (selectedDateRange === "Last 3 Months") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() - 3);
      const formattedDate = newDate.toISOString().slice(0, 10);
      setStartDate(formattedDate);
      setEndDate(formattedToday);
    } else if (selectedDateRange === "Last Year") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() - 1);
      const formattedDate = newDate.toISOString().slice(0, 10);
      setStartDate(formattedDate);
      setEndDate(formattedToday);
    } else if (selectedDateRange === "Lifetime") {
      const formattedToday = date.toISOString().slice(0, 10);
      const newDate = new Date(2024, 0, 1);
      const formattedDate = newDate.toISOString().slice(0, 10);
      setStartDate(formattedDate);
      setEndDate(formattedToday);
    }
  }, [selectedDateRange]);

  const handleMenuClick = (range: string) => {
    setSelectedDateRange(range);
    onMenuClose();
  };

  console.log("row selection -> ", rowSelection);

  const fetchOrders = async (page: number) => {
    const orders = await getOrders(page, 50, token as string);
    console.log("all orders -> ", orders);
    setAllOrders(orders.data.orders);
    let pages;
    if (orders.data.total % 50 == 0) pages = orders.data.total / 50;
    setTotalPages(Math.ceil(orders.data.total / 50));
  };

  console.log("all orders from  -> ", allOrders);

  useEffect(() => {
    fetchOrders(pagination.pageIndex + 1);
  }, [pagination.pageIndex]);

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleStatusChange = async (status: string, order: any) => {
    const response = await changeStatusOrder(
      { id: order._id, status: status },
      token as string
    );
    if (response.success) {
      toast.success(response.message);
      fetchOrders(pagination.pageIndex + 1);
    }
  };

  console.log("order -> ", multiSelectedOrder);

  // Type the columns array with your Product type
  const columns: ColumnDef<Order, any>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      header: "Serial",
      accessorKey: "serialNumber",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.serialNumber}</Text>
          <Text>
            {row.original.createdAt &&
              new Date(row.original.createdAt).toLocaleString()}
          </Text>
        </Box>
      ),
    },
    {
      header: "Total Payable",
      accessorKey: "payablePrice",
    },
    {
      header: "Due Price",
      accessorKey: "duePrice",
    },
    {
      header: "Payment",
      accessorKey: "paymentStatus",
    },
    {
      header: "Customer",
      cell: ({ row }) => (
        <Box>
          <Text>{row.original.customer.name}</Text>
          <Text>{row.original.customer.phone}</Text>
          <Text>
            {row.original.addressInfo.details},{" "}
            {row.original.addressInfo.upazila},{" "}
            {row.original.addressInfo.district}
          </Text>
        </Box>
      ),
    },
    {
      header: "Products",
      cell: ({ row }) => (
        <Box>
          {row.original.products.map((product, index) => (
            <Text>
              {index + 1}. {product.nameBangla}
            </Text>
          ))}
        </Box>
      ),
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <Box display={"flex"} gap={1}>
          <IconButton
            aria-label="View Product"
            icon={<FaEye />}
            onClick={() => {
              onOrderDetailsOpen();
              setSelectedOrder(row.original);
            }}
          />
          <IconButton
            aria-label="View Product"
            icon={<FaEdit />}
            onClick={() => navigate(`/edit-order/${row.original._id}`)}
          />
          {/* <Button ml={2} colorScheme="blue" onChange={() => handleOrderStatus(row.original)}>
            Change Status
          </Button> */}
          <Select
            w={"125px"}
            value={row.original.currentStatus}
            placeholder="Select option"
            onChange={(e) => handleStatusChange(e.target.value, row.original)}
          >
            <option value="pending">Pending</option>
            <option value="confirm">Confirm</option>
            <option value="processing">Processing</option>
            <option value="picked">Picked</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
            <option value="canceled">Canceled</option>
            <option value="returned">Returned</option>
            <option value="refund">Refund</option>
          </Select>
        </Box>
      ),
    },
  ];

  const table = useReactTable({
    data: allOrders,
    columns,
    state: {
      rowSelection,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const handlePageChange = (page: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: page - 1 }));
  };

  useEffect(() => {
    const keys = Object.keys(rowSelection);
    const newSelected: any[] = [];
    keys.map((item: any) => {
      newSelected.push(allOrders[Number(item)]._id);
    });
    setMultiSelectedOrder(newSelected);
  }, [rowSelection]);

  const handleSearch = async () => {
    const response = await fetchOrdersFromSearch(
      {
        searchValue: searchValue,
        filteringDate: { startDate: startDate, endDate: endDate },
      },
      token as string
    );
    console.log("response -> ", response);
    if (response.success) {
      setAllOrders(response.data.orders);
      let pages;
      if (response.data.total % 50 == 0) pages = response.data.total / 50;
      setTotalPages(Math.ceil(response.data.total / 50));
    }
  };

  const handleMultiStatusChange = async (value: string) => {
    const response = await multiOrderChangeStatus(
      { orderIds: multiSelectedOrder, status: value },
      token as string
    );
    if (response.success) {
      toast.success(response.message);
      fetchOrders(pagination.pageIndex + 1);
    }
  };

  const handleCSV = async () => {
    const response = await downloadCsv(
      { orderIds: multiSelectedOrder },
      token as string
    );
    console.log("csv -> ", response);
    if (response.success) {
      const uri = `${process.env.REACT_APP_BASE_URL}/${response.data}`; // Replace with your CSV file URI
      const link = document.createElement("a");
      link.href = uri;
      link.download = "Orders.csv"; // The file name you want to save as
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePrint = async () => {
    const response = await printPdf(
      { orderIds: multiSelectedOrder },
      token as string
    );
    console.log("pdf res -> ", response);
    if (response.success) {
      const blob = await pdf(<GeneratePDF data={response.data} />).toBlob();
      const url = URL.createObjectURL(blob);

      if (iframeRef.current) {
        iframeRef.current.src = url;

        iframeRef.current.onload = () => {
          iframeRef.current?.contentWindow?.print();
          URL.revokeObjectURL(url);
        };
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOrderDetailsOpen}
        onClose={onOrderDetailsClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns={"1fr 1fr"} gap={2}>
              <GridItem>
                <Text>Serial: {selectedOrder?.serialNumber}</Text>
                <Text>Customer Name: {selectedOrder?.customer.name}</Text>
                <Text>Phone: {selectedOrder?.customer.phone}</Text>
                <Text>
                  Address: {selectedOrder?.addressInfo.details},{" "}
                  {selectedOrder?.addressInfo.upazila},{" "}
                  {selectedOrder?.addressInfo.details}
                </Text>
              </GridItem>
              <GridItem>
                <Text>Status: {selectedOrder?.currentStatus}</Text>
                <Text>Payment Status: {selectedOrder?.paymentStatus}</Text>
                <Text>Total Payable: {selectedOrder?.payablePrice}</Text>
                <Text>
                  Order Time:{" "}
                  {selectedOrder?.createdAt &&
                    new Date(selectedOrder.createdAt).toLocaleString()}
                </Text>
              </GridItem>
              {selectedOrder?.products?.map((product) => (
                <GridItem colSpan={2}>
                  <Grid templateColumns={"150px 1fr"} gap={5} mt={3}>
                    <GridItem>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${product?.image}`}
                        alt="Product"
                        style={{ width: "150px", height: "230px" }}
                      />
                    </GridItem>
                    <GridItem>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Product Name(BN):{" "}
                        </Text>
                        {product?.nameBangla}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Product Name(EN):
                        </Text>{" "}
                        {product?.nameEnglish}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Authors:
                        </Text>{" "}
                        {product?.authors
                          ?.map((item) => item?.nameBangla)
                          .join(", ")}
                      </Text>
                      {product?.translators?.length > 0 && (
                        <Text>
                          <Text as={"span"} fontWeight={"bold"}>
                            Translators:
                          </Text>{" "}
                          {product?.translators
                            ?.map((item: any) => item?.nameBangla)
                            .join(", ")}
                        </Text>
                      )}
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Unit Price:
                        </Text>{" "}
                        {product?.perPrice}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Ordered Quantity:
                        </Text>{" "}
                        {product?.quantity}
                      </Text>
                      <Text>
                        <Text as={"span"} fontWeight={"bold"}>
                          Total Price:
                        </Text>{" "}
                        {product?.totalPrice}
                      </Text>
                    </GridItem>
                  </Grid>
                </GridItem>
              ))}
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onOrderDetailsClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Heading as={"h3"} size={"lg"} mt={5} ml={5}>
          Order List
        </Heading>
        <Flex gap={2} mt={3}>
          <Input
            type="text"
            w={"350px"}
            placeholder="Search Orders by serial or phone"
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <Menu isOpen={isMenuOpen}>
            <MenuButton
              as={Button}
              leftIcon={isMenuOpen ? <IoIosCloseCircle /> : <FaCalendarDay />}
              onClick={isMenuOpen ? onMenuClose : onMenuOpen}
              variant="outline"
              size="md"
              mt={1}
            >
              {selectedDateRange || "Select Date Range"}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleMenuClick("Today")}>
                Today
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("Yesterday")}>
                Yesterday
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("Last 7 Days")}>
                Last 7 Days
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("Last Month")}>
                Last Month
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("Last 3 Months")}>
                Last 3 Months
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("Last Year")}>
                Last Year
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => handleMenuClick("Lifetime")}>
                Lifetime
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("Select Date Range")}>
                Select Date Range
              </MenuItem>
            </MenuList>
          </Menu>
          <Button mt={1} onClick={handleSearch}>
            Search
          </Button>
        </Flex>
        <Button
          mt={4}
          mr={5}
          color={"white"}
          colorScheme="blue"
          onClick={() => navigate("/manual-order")}
        >
          Manual Order
        </Button>
      </Box>
      {!isEmptyObject(rowSelection) && (
        <Center mt={5}>
          <Select
            w={"200px"}
            mr={3}
            // value={row.original.currentStatus}
            placeholder="Select status"
            onChange={(e) => handleMultiStatusChange(e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="confirm">Confirm</option>
            <option value="processing">Processing</option>
            <option value="picked">Picked</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
            <option value="canceled">Canceled</option>
            <option value="returned">Returned</option>
            <option value="refund">Refund</option>
          </Select>
          <Button mr={3} onClick={handleCSV}>
            CSV
          </Button>
          {/* <Button>Print</Button> */}
          {/* <DownloadButton /> */}
          <Button colorScheme="teal" onClick={handlePrint}>
            Print PDF
          </Button>
          {/* Hidden iframe to handle printing */}
          <iframe ref={iframeRef} style={{ display: "none" }} />
        </Center>
      )}
      <Box mt={5}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Pagination
        currentPage={pagination.pageIndex + 1}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}
