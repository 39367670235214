import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../redux/store";
import {
  activeOrDeactiveCategory,
  createCategory,
  getAllCategory,
  updateCategory,
} from "../services/axios.services";

interface FormData {
  banglaName: string;
  englishName: string;
}

interface Category {
  _id?: number;
  nameEnglish: string;
  nameBangla: string;
  isActive?: boolean;
  sellingCount?: number;
  slug?: string;
}

export default function Categories() {
  const token = useSelector((state: RootState) => state.user.token);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [categoryData, setCategoryData] = useState<Category[]>([]);
  const [activeCategory, setActiveCategory] = useState<Category>();
  const [activeBanglaName, setActiveBanglaName] = useState<string>("");
  const [activeEnglishName, setActiveEnglishName] = useState<string>("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const fetchCategories = async () => {
    try {
      const response = await getAllCategory(token as string);
      setCategoryData(response?.data);
    } catch (err: any) {
      console.error("Error fetching categories:", err.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const onSubmit = async (data: FormData) => {
    try {
      const categoryData = await createCategory(
        data.banglaName,
        data.englishName,
        token as string
      );
      if (categoryData.data.success) {
        reset();
        fetchCategories();
      }
      toast(categoryData.data.message);
    } catch (err: any) {
      console.log("error -> ", err.message);
      toast(err.message);
    }
  };

  const handleEdit = async () => {
    const categoryData = await updateCategory(
      {
        _id: activeCategory?._id,
        nameEnglish: activeEnglishName,
        nameBangla: activeBanglaName,
      },
      token as string
    );
    if (categoryData?.data.success) {
      toast.success(categoryData?.data.message);
      fetchCategories();
      onClose();
    }
  };

  const handleAction = async (data: any) => {
    const categoryAction = await activeOrDeactiveCategory(
      { _id: data?._id, isActive: !data?.isActive },
      token as string
    );
    if (categoryAction.data.success) {
      toast.success(categoryAction.data.message);
      fetchCategories();
    }
  };

  const columns: ColumnDef<Category, any>[] = [
    {
      header: "Name(BN)",
      accessorKey: "nameBangla",
    },
    {
      header: "Name(En)",
      accessorKey: "nameEnglish",
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <Flex gap={2} justifyContent={"center"}>
          <IconButton
            aria-label="View Product"
            icon={<FaEdit />}
            color={"black"}
            colorScheme="white"
            border={"1px solid black"}
            onClick={() => {
              onOpen();
              setActiveCategory(row?.original);
            }}
          />
          <Button
            colorScheme={row?.original?.isActive ? "red" : "green"}
            onClick={() => {
              handleAction(row?.original);
            }}
          >
            {row?.original?.isActive ? "Deactive" : "Active"}
          </Button>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    if (activeCategory) {
      setActiveBanglaName(activeCategory.nameBangla);
      setActiveEnglishName(activeCategory.nameEnglish);
    }
  }, [activeCategory]);

  console.log("active category -> ", activeCategory);

  const FilterInput: React.FC<{ column: any }> = ({ column }) => {
    const columnFilterValue = column.getFilterValue();

    return (
      <input
        value={(columnFilterValue ?? "") as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder={`Search...`}
      />
    );
  };

  const table = useReactTable({
    data: categoryData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns={"1fr 1fr"} gap={5}>
              <GridItem>
                <FormLabel>Category Name in Bangla</FormLabel>
                <Input
                  type="text"
                  value={activeBanglaName}
                  onChange={(e) => setActiveBanglaName(e.target.value)}
                />
                {activeBanglaName == "" && (
                  <Text color={"tomato"} fontSize={"13px"}>
                    Bangla Name is required.
                  </Text>
                )}
              </GridItem>
              <GridItem>
                <FormLabel>Category Name in English</FormLabel>
                <Input
                  type="text"
                  value={activeEnglishName}
                  onChange={(e) => setActiveEnglishName(e.target.value)}
                />
                {activeEnglishName == "" && (
                  <Text color={"tomato"} fontSize={"13px"}>
                    English Name is required.
                  </Text>
                )}
              </GridItem>
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleEdit}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box p={5}>
        <Heading as="h2" size="lg" mb={4}>
          Create Category
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns={"1fr 1fr 1fr"} gap={5}>
            <GridItem>
              <FormLabel>Category Name in Bangla</FormLabel>
              <Input
                type="text"
                {...register("banglaName", {
                  required: "Bangla name is required",
                  maxLength: {
                    value: 50,
                    message: "Maximum length is 50 characters",
                  },
                })}
              />
              {errors.banglaName && (
                <Text color={"tomato"}>{errors.banglaName.message}</Text>
              )}
            </GridItem>
            <GridItem>
              <FormLabel>Category Name in English</FormLabel>
              <Input
                type="text"
                {...register("englishName", {
                  required: "English name is required",
                  maxLength: {
                    value: 50,
                    message: "Maximum length is 50 characters",
                  },
                })}
              />
              {errors.englishName && (
                <Text color={"tomato"}>{errors.englishName.message}</Text>
              )}
            </GridItem>
            <GridItem mt={8}>
              <Button type="submit" colorScheme="blue" px={10}>
                Create
              </Button>
            </GridItem>
          </Grid>
        </form>
      </Box>
      <Box p={5}>
        <Heading as="h3" size="md" mb={4}>
          Category list
        </Heading>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                    <div>
                      {header.column.getCanFilter() ? (
                        <FilterInput column={header.column} />
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}
