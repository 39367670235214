import { ChakraProvider, theme } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SharedLayout from "./components/Shared/SharedLayout";
import "./global.css";
import AllAuthorsList from "./pages/AllAuthorsList";
import AllProducts from "./pages/AllProducts";
import AllTranslatorsList from "./pages/AllTranslatorsList";
import AuthorRegistration from "./pages/AuthorRegistration";
import Categories from "./pages/Categories";
import CreateProduct from "./pages/CreateProduct";
import Dashboard from "./pages/Dashboard";
import EditOrder from "./pages/EditOrder";
import EditProduct from "./pages/EditProduct";
import Login from "./pages/Login";
import ManualOrder from "./pages/ManualOrder";
import OrderList from "./pages/OrderList";
import PendingAuthors from "./pages/PendingAuthors";
import PendingTranslators from "./pages/PendingTranslators";
import Settings from "./pages/Settings";
import TranslatorRegistration from "./pages/TranslatorRegistration";
import UserList from "./pages/UserList";
import PrivateRoutes from "./PrivateRoutes";
import { RootState } from "./redux/store";

export default function App() {
  const token = useSelector((state: RootState) => state.user.token);
  const logo = useSelector((state: RootState) => state.logo.logo);

  return (
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes token={token} />}>
            <Route path="/" element={<SharedLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/create-product" element={<CreateProduct />} />
              <Route path="/all-products" element={<AllProducts />} />
              <Route path="/all-authors" element={<AllAuthorsList />} />
              <Route path="/all-translators" element={<AllTranslatorsList />} />
              <Route path="/orders" element={<OrderList />} />
              <Route path="/manual-order" element={<ManualOrder />} />
              <Route path="/user-list" element={<UserList />} />
              <Route path="/edit-order/:id" element={<EditOrder />} />
              <Route
                path="/author-registration"
                element={<AuthorRegistration />}
              />
              <Route
                path="/translator-registration"
                element={<TranslatorRegistration />}
              />
              <Route path="/pending-authors" element={<PendingAuthors />} />
              <Route path="/edit-product/:id" element={<EditProduct />} />
              <Route
                path="/pending-translators"
                element={<PendingTranslators />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
