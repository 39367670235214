/* eslint-disable react-hooks/rules-of-hooks */
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { AuthorRegistrationFormData } from "../interfaces/Author.interface";

// authorization header
// const token = useSelector((state: RootState) => state.user.token);
// const headerAuthorization: AxiosRequestConfig = {
//   headers: {
//     Authorization: token as string,
//   },
// };

// Create an instance of axios with default configuration
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to handle login
export const handleLogin = async (email: string, password: string) => {
  try {
    const response = await apiClient.post("admin/login", {
      email,
      password,
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Login error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message || "Login failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while logging in");
    }
  }
};

// Function to get Dashboard data
export const getDashboardData = async (
  startDate: string,
  endDate: string,
  token: string
) => {
  try {
    const response = await apiClient.get(
      `admin/dashboard?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Dashboard data fetching error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Dashboard data fetching failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while fetching Author list");
    }
  }
};

// Function to get settings image and data
export const getSettingsData = async () => {
  try {
    const response = await apiClient.get("settings/view");
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Settings error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Settings failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while fetching settings");
    }
  }
};

// Function to handle Logo Upload
export const handleSettingsImageUpload = async (
  reqBody: any | any[],
  type: string,
  token?: string
) => {
  try {
    const response = await apiClient.post(`settings/${type}`, reqBody, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Upload error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Upload failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while Uploading");
    }
  }
};

// Function to remove slider
export const handleRemoveSlider = async (id: string, token: string) => {
  try {
    const response = await apiClient.put(`settings/sliders/remove/${id}`, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Upload error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Upload failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while Uploading");
    }
  }
};

// Function to create category
export const createCategory = async (
  banglaName: string,
  englishName: string,
  token: string
) => {
  try {
    const response = await apiClient.post(
      "category/create",
      {
        nameBangla: banglaName,
        nameEnglish: englishName,
      },
      {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      }
    );
    return response;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Creation failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while creation");
    }
  }
};

// Function to get all active deactive category
export const getAllCategory = async (token: string) => {
  try {
    const response = await apiClient.get("category/admin/all", {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while fetching Category");
    }
  }
};

// Fucntion to get all active categories
export const getAllActiveCategory = async (token: string) => {
  try {
    const response = await apiClient.get("category/all", {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while fetching Category");
    }
  }
};

// Function to update the category
export const updateCategory = async (data: any, token: string) => {
  try {
    const response = await apiClient.put(
      "category/update",
      {
        id: data._id,
        nameBangla: data.nameBangla,
        nameEnglish: data.nameEnglish,
      },
      {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      }
    );
    return response;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Creation failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while creation");
    }
  }
};

// Function to update the category
export const activeOrDeactiveCategory = async (data: any, token: string) => {
  try {
    const response = await apiClient.put(
      "category/active-inactive",
      {
        id: data._id,
        isActive: data.isActive,
      },
      {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      }
    );
    return response;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Creation failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while creation");
    }
  }
};

// Function to make category active or deactive
export const setCategoryToActiveOrDeactive = async (
  data: any,
  token: string
) => {
  try {
    const response = await apiClient.put(
      "category/active-inactive",
      {
        id: data._id,
        isActive: data.isActive,
      },
      {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      }
    );
    return response;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Creation failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while creation");
    }
  }
};

// Function to get all author list
export const getAuthorList = async (token: string) => {
  try {
    const response = await apiClient.get("authors/list-all", {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Author list error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Author view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while fetching Author list");
    }
  }
};

// Function to get all translator list
export const getTranslatorList = async (token: string) => {
  try {
    const response = await apiClient.get("translators/list", {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Translator list error:", error.response.data);
      throw new Error(
        (error.response.data as { message: string }).message ||
          "Translator view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      throw new Error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      throw new Error("An error occurred while fetching Translator list");
    }
  }
};

// Function to create product
export const createProduct = async (data: any, token: string) => {
  try {
    const response = await apiClient.post("book/create", data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Product create error:", error.response.data);
      return error.response;
      // throw new Error(
      //   (error.response.data as { message: string }).message ||
      //     "Creation failed"
      // );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while creation");
    }
  }
};

// Function to update product
export const updateProduct = async (data: any, token: string) => {
  try {
    const response = await apiClient.put("book/update", data, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (err) {
    const error = err as AxiosError;

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Product create error:", error.response.data);
      return error.response;
      // throw new Error(
      //   (error.response.data as { message: string }).message ||
      //     "Creation failed"
      // );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while creation");
    }
  }
};

export const getAllProducts = async (
  pageNumber: number,
  resultsPerPage: number,
  token: string
) => {
  try {
    const response = await apiClient.get(
      `book/admin?pageNumber=${pageNumber}&resultsPerPage=${resultsPerPage}`,
      {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      }
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

export const getAllDistricts = async (token: string) => {
  try {
    const response = await apiClient.get(`location/district`, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

export const getUpazilaByDistrict = async (
  districtId: string,
  token: string
) => {
  try {
    const response = await apiClient.get(`location/upazila/${districtId}`, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

export const getSingleOrderView = async (
  orderId: string,
  token: string
) => {
  try {
    const response = await apiClient.get(`order/view/by-admin/${orderId}`, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

export const getAllUsers = async (
  token: string
) => {
  try {
    const response = await apiClient.get(`users/list`, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

export const getSingleProduct = async (id: string, token: string) => {
  try {
    const response = await apiClient.get(`book/by-view/${id}`, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

export const getSearchProducts = async (
  pageNumber: number,
  resultsPerPage: number,
  token: string,
  value: string
) => {
  try {
    const response = await apiClient.post(
      `book/search?pageNumber=${pageNumber}&resultsPerPage=${resultsPerPage}`,
      { value: value },
      {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      }
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

// Function to handle author or translator registration
export const handleAuthorOrTranslatorRegistration = async (
  data: AuthorRegistrationFormData,
  type: string,
  token: string
) => {
  try {
    const response = await apiClient.post(`${type}/registration/manual`, data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Login error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Login failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while registration");
    }
  }
};

// Functions to get unapproved author or translator list
export const getUnapprovedAuthorOrTranslator = async (
  type: string,
  token: string
) => {
  try {
    const response = await apiClient.get(`${type}/unapproved`, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    console.log("response form -> ", response);
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          `${type} view failed`
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error(`An error occurred while fetching ${type}`);
    }
  }
};

// Function to approve or reject of author or translator
export const approveOrRejectAuthorOrTranslator = async (
  data: any,
  type: string,
  token: string
) => {
  try {
    const response = await apiClient.put(`${type}/approved-remove`, data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Login error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Error"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred");
    }
  }
};

// Function to get all orders
export const getOrders = async (
  pageNumber: number,
  resultsPerPage: number,
  token: string
) => {
  try {
    const response = await apiClient.get(
      `order/views/by-admin?resultsPerPage=${resultsPerPage}&pageNumber=${pageNumber}`,
      {
        headers: {
          Authorization: token, // Include the token in the Authorization header
        },
      }
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category view error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Category view failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching Category");
    }
  }
};

// Function to change order status
export const changeStatusOrder = async (data: any, token: string) => {
  try {
    const response = await apiClient.put(`order/status-update`, data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Login error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Error"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred");
    }
  }
};

// Function to create manual order
export const createManualOrder = async (data: any, token: string) => {
  try {
    const response = await apiClient.post("order/create-by-admin", data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Creation failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while creation");
    }
  }
};

// Function to download csv for multi order
export const downloadCsv = async (data: any, token: string) => {
  try {
    const response = await apiClient.post("order/multiple-csv", data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Download failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while downloading");
    }
  }
};

// Function to change status for multiple orders
export const multiOrderChangeStatus = async (data: any, token: string) => {
  try {
    const response = await apiClient.put(`order/multiple-status-update`, data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Login error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Error"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred");
    }
  }
};

// Function to fetch searched orders
export const fetchOrdersFromSearch = async (data: any, token: string) => {
  try {
    const response = await apiClient.post("order/search-filter", data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Download failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while downloading");
    }
  }
};

// Function to download csv for multi order
export const printPdf = async (data: any, token: string) => {
  try {
    const response = await apiClient.post("order/multiple-invoice", data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Category create error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message ||
          "Download failed"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred while downloading");
    }
  }
};

// Functions to put request from authors list actions
export const handleAuthorListActions = async (data: any, token: string) => {
  try {
    const response = await apiClient.put(`authors/active-inactive`, data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Login error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Error"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred");
    }
  }
};

// Function to edit order
export const editOrder = async (data: any, token: string) => {
  try {
    const response = await apiClient.put(`order/update-by-admin`, data, {
      headers: {
        Authorization: token, // Include the token in the Authorization header
      },
    });
    return response.data;
  } catch (err) {
    const error = err as AxiosError; // Cast the error to AxiosError

    // Handle error appropriately
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Login error:", error.response.data);
      toast.error(
        (error.response.data as { message: string }).message || "Error"
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
      toast.error("No response from server");
    } else {
      // Something happened in setting up the request
      console.error("Error:", error.message);
      toast.error("An error occurred");
    }
  }
};
